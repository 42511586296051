<template>
  <div>
    <div><label class="form-control-label">Your analytics</label></div>
    <div class="bg-white py-2 px-3">
      <analytics-date v-model="date" :containerStyle="{height: '34px'}"/>
      <div class="d-flex mt-3 mb-2">
        <div class="w-70 text-sm">Texts sent</div>
        <div>{{ textAnalytics.totalSentMessages }}</div>
      </div>
      <div class="d-flex">
        <div class="w-70 text-sm">Texts received</div>
        <div>{{ textAnalytics.totalReceivedMessages }}</div>
      </div>
    </div>
  </div>
</template>
<script>
import {mapActions, mapGetters} from 'vuex';
import moment from 'moment';
import AnalyticsDate from './AnalyticsDate.vue';
import {APP_DATE_FORMAT} from '@/util/config.js';
import {GET_USER_ANALYTICS} from '@/store/storeActions';

export default {
  components: {
    AnalyticsDate,
  },
  data() {
    return {
      date: moment().format(APP_DATE_FORMAT),
    };
  },
  computed: {
    ...mapGetters([
      'userId',
      'textAnalytics',
    ]),
  },
  watch: {
    date: {
      handler: function(newVal) {
        this.getAnalytics(newVal);
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions([
      GET_USER_ANALYTICS,
    ]),
    getAnalytics: function(date) {
      this.GET_USER_ANALYTICS({date});
    },
  },
};
</script>

<style scoped lang="scss">
</style>
